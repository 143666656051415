/* You can add global styles to this file, and also import other style files */
.clear {
  clear: both;
}
.hidden-section {
  display: none;
}
.game-achievement-div {
  font-size: 20px;
  font-weight: bold;
  width: 800px;
  float: left;
}
.game-achievement-span {
  border: 3px solid #000;
  padding: 3px;
  display: inline-block;
  min-width: 150px;
  text-align: center;
}
.instruction-container {
  margin: 10px auto 0;
}
.before-game-title {
  color: #33cc33;
  text-decoration: underline;
  font-size: 20px;
}
.instruction-ul {
  font-size: 20px;
}
.start-btn {
  float: right;
}
.img-total-coins,
.img-total-rocks {
  width: 40px;
}
.end-btn {
  float: right;
  margin-right: 10px;
}
.trial-next-btn-lg,
.trial-next-btn-lg:active,
.trial-next-btn-lg:focus {
  width: 200px;
  height: 100px;
  border: none;
  box-shadow: none !important;
  padding: 0;
  background-image: url("/assets/images/trial-next-btn-lg.png") !important;
  background-size: 100% 100%;
}
